<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-btn small fab color="info" @click="openCreateInvoiceDialog" class="ml-2"><v-icon>mdi-plus</v-icon></v-btn>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!loader">
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
                <template v-slot:item.customerName="{ item }">
                  <span>{{ item.customerName?item.customerName:"-" }}</span>
                </template>
                <template v-slot:item.csrUser="{ item }">
                  <span v-if="item.csrUser">{{item.csrUser.firstName[0]}}. {{item.csrUser.lastName}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="utils.getStatusColor(item.status)">{{utils.parseStatus(item.status)}}</v-chip>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{utils.formatDate(item.createdAt, 'short')}}</span>
                </template>
                <template v-if="getGlobalValue('VEC_INCLUDES_AR')==='true'" v-slot:item.daysLate="{ item }">
                  <span v-if="!item.daysLate" >{{item.daysLate!=0?'-':0}}</span>
                  <v-chip  color="green" small v-if="item.daysLate && item.daysLate < 30 " style="color:white;">{{item.daysLate}}</v-chip>
                  <v-chip  color="orange" small v-else-if="item.daysLate && item.daysLate < 60 " style="color:white;">{{item.daysLate}}</v-chip>
                  <v-chip  color="red" small v-else-if="item.daysLate && item.daysLate >= 60 " style="color:white;">{{item.daysLate}}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn class="mr-1" v-if="isAllowed('order', 'r')" fab x-small color="info" @click="rowClick(item)">
                    <v-icon>mdi-file</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.metadata.grandTotal="{ item }">
                  <span v-if="item.metadata&&item.metadata.grandTotal">{{ formatPrice(item.metadata.grandTotal?item.metadata.grandTotal:0) }}</span>
                  <span v-else>-</span>
                </template>
                <template v-if="getGlobalValue('VEC_INCLUDES_AR')==='true'" v-slot:item.balance="{ item }">
                  <span v-if="item.balance">{{ formatPrice(item.balance?item.balance:0) }}</span>
                  <span v-else>-</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createInvoiceDialog.isOpen"
      width="600"
      fixed
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Confirm Create Invoice</b>
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="closeCreateInvoiceDialog">Cancel</v-btn>
          <v-btn color="success" text @click="attemptCreateInvoice()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  import utils from "./../../plugins/helpers"
  export default {
    data () {
      return {
        utils:utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Created', value: 'createdAt' },
          { text: 'Customer', value: 'customerName' },
          { text: 'CSR', value: 'csrUser' },
          { text: 'Delivery Type', value: 'deliveryInfo.deliveryType' },
          { text: '# Items', value: 'itemCount' },
          { text: 'Grand Total', value: 'metadata.grandTotal' },
          { text: 'Balance', value: 'balance' },
          { text: 'DSLP', value: 'daysLate' },
          // { text: 'Actions', value: 'actions' },
        ],
        itemHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Name', value: 'name' },
          { text: 'QTY', value: 'quantity' },
          { text: 'Unit Price', value: 'unitPrice' },
          { text: 'Line Total', value: 'lineTotal' },
        ],
        editMode: false,

        selectedOrder: null,

        data: {},
        allData: [],
        singular: "Order",
        singularLower: "order",
        plural: "Orders",
        pluralLower: "orders",

        createInvoiceDialog: {
          isOpen: false,
          customerId: ''
        },
        rowClicks: 1,
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getUsername', 'getEndpoint', 'isAllowed', 'getPerms', 'getId', 'lookupUsername', 'getGlobalValue'])
    },
    methods: {
      ...mapMutations(["setSnack"]),
      async rowClick(row){
        // this.rowClicks++
        // let dblClkWait = setTimeout(()=>{
        //   this.rowClicks = 1
        // },500);
        // if(this.rowClicks%2!==0){
        //   this.rowClicks = 1
        //   clearTimeout(dblClkWait)
        //   return 
        // }
        await this.$router.push({path: `/orders/view/${row.id}`});
        // if(!(this.$route.query.order && this.$route.query.order==row.id)) {
        //   window.scrollTo({top: 0});
        //   await this.$router.replace({path: `/${this.pluralLower}`, query: {order: row.id}});
        //   this.selectedOrder = row;
        //   console.log(this.selectedOrder)
        // }
      },
      async closeRow(){
        await this.$router.replace({path: `/${this.pluralLower}`, query: null});
        this.selectedOrder = null;
      },
      openOrder(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      openCustomer(row){
        this.$router.push({ path: `/customers/view/${row}`})
      },
      openEmployee(row){
        this.$router.push({ path: `/users/view/${row}`})
      },
      async getAllData(){
        try {
          if(this.getGlobalValue('VEC_INCLUDES_AR')!=='true'){
            let ix = this.headers.findIndex(x=>x.value=="daysLate")
            if(ix != -1) this.headers.splice(ix,1)
          }
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableData`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data
          if(this.$route.query.order){
            let id = parseInt(this.$route.query.order);
            this.selectedOrder = this.allData.find(x => x.id===id);
            if(!this.selectedOrder) this.closeRow()
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false;
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      openCreateInvoiceDialog(){
        this.createInvoiceDialog.isOpen = true
      },
      closeCreateInvoiceDialog(){
        this.createInvoiceDialog.isOpen = false
      },
      async attemptCreateInvoice(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      },
      parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      goToOrder(){
        console.log("Hi")
      }
    }
  }
</script>
